'use client';

import ErrorFallback from '@/components/ErrorFallback/ErrorFallback';

export default function Error() {
  return (
    <html>
      <body>
        <ErrorFallback />
      </body>
    </html>
  );
}
